<template>
  <q-drawer
      v-if="isEnabledSidebar"
      v-model="isOpen"
      show-if-above
      side="left"
      :class="`bg-animated styled-scrollbar ${classes({ prefix: 'bg', invert: true })}`"
      :width="$q.screen.width < 700 ? 270 : 215"
      bordered
  >
    <div
        class="row"
        style="min-height: 100%; flex-direction: column;"
    >
      <div
          v-if="$q.screen.width < 700"
          class="q-mx-sm q-mb-sm q-pb-sm text-white border-bottom"
      >
        <div
            class="relative text-center q-pt-sm"
            @click="handleOpenProfile"
        >
          <div class="absolute-top-right q-pt-sm">
            <q-btn
                color="negative"
                icon="logout"
                size="sm"
                @click="logout"
            />
          </div>

          <q-avatar size="70px" class="relative q-mr-xs q-pt-xs">
            <div class="absolute-top-right">
              <q-icon name="help" size="20px"/>
            </div>

            <img src="https://cdn.quasar.dev/img/boy-avatar.png">
          </q-avatar>

          <div class="text-subtitle2 q-my-sm col" style="word-wrap: break-word;">
            <div class="q-mb-xs">
              {{ userdata.email }}
            </div>
          </div>
        </div>

        <q-card dark class="q-mb-sm q-mx-sm">
          <q-expansion-item
              v-model="isOpenExpansion"
              :label="$t('Settings')"
              class="text-left"
              dense
          >
            <div class="q-pa-xs">
              <q-select
                  standout="bg-teal"
                  dark
                  class="q-mb-sm"
                  :model-value="locale.name"
                  :options="locales"
                  :label="$t('Language')"
              >
                <template v-slot:option="props">
                  <div
                      class="q-pa-sm card--clickable card--clickable-no-scale"
                      :class="props.selected ? 'bg-success' : ''"
                      style="min-width: 150px;"
                      @click="handleLocale(props.opt)"
                  >
                    {{ props.opt.name }}
                  </div>
                </template>
              </q-select>

              <q-select
                  v-if="themes.length > 1"
                  standout="bg-teal"
                  dark
                  :model-value="theme"
                  :options="themes"
                  :label="$t('Theme')"
              >
                <template
                    v-slot:option="props"
                >
                  <div
                      class="q-pa-sm card--clickable card--clickable-no-scale"
                      :class="props.selected ? 'bg-success' : ''"
                      style="min-width: 150px;"
                      @click="handleTheme(props.opt)"
                  >
                    {{ props.opt.name }}
                  </div>
                </template>
              </q-select>

              <q-toggle
                  :model-value="$q.dark.isActive"
                  :label="$t('Dark Mode')"
                  @update:model-value="handleDarkMode"
              />

              <q-toggle
                  :model-value="appOptions.debug"
                  :label="$t('Developer mode')"
                  @update:model-value="handleDebug"
              />
            </div>
          </q-expansion-item>
        </q-card>

        <user ref="userModal"/>
      </div>

      <div
          v-if="isMobile"
          class="text-center q-pa-xs"
      >
        <q-btn
            :label="$t('Terminal')"
            color="lime-14"
            text-color="dark"
            size="md"
            style="min-width: 150px;"
            @click="handleClick({ path: '/terminal' })"
        />
      </div>

      <q-tree
          ref="tree"
          :nodes="menu"
          :selected="selected"
          node-key="path"
          label-key="name"
          :class="`bg-animated ${padding}`"
      >
        <template v-slot:header-generic="prop">
          <div
              :class="prop.node.options && prop.node.options.styleClasses"
              :style="prop.node.options && prop.node.options.styles"
          >
            <div
                :class="`row items-center q-py-xs full-width ${selected === prop.node.path ? 'text-light-blue-9' : ''}`"
                @click="handleClick(prop.node, $event)"
            >
              <q-icon
                  v-if="prop.node.icon"
                  :name="prop.node.icon"
                  class="q-mr-sm f-s-16"
              />

              <div class="f-s-12">
                {{ $t(prop.node.name) }}
              </div>
            </div>
          </div>
        </template>
      </q-tree>

      <q-space/>

      <q-btn
          color="accent"
          class="text-white"
          :label="$t('Help')"
          unelevated
          no-caps
      >
        <q-menu fit>
          <q-list>
            <q-item
                clickable
                @click="toggleQuickstart"
            >
              <q-item-section>
                {{ $t('Quickstart') }}
              </q-item-section>
            </q-item>

            <q-item
                v-if="isEnabledChat"
                clickable
                @click="toggleHelp"
            >
              <q-item-section>
                {{ $t('Live chat') }}
              </q-item-section>
            </q-item>

            <q-item
                clickable
                :href="appOptions.knowledge"
                target="blank"
            >
              <q-item-section>
                <span>
                  {{ $t('Knowledge base') }}

                  <q-badge rounded color="transparent">
                    <q-icon
                        name="launch"
                        size="14px"
                        :color="$q.dark.isActive ? 'white' : 'dark'"
                    />
                  </q-badge>
                </span>
              </q-item-section>
            </q-item>

            <q-item
                clickable
                :href="appOptions.support"
            >
              <q-item-section class="">
                <span>
                  {{ $t('Support') }}

                  <q-badge rounded color="transparent">
                    <q-icon
                        name="launch"
                        size="14px"
                        :color="$q.dark.isActive ? 'white' : 'dark'"
                    />
                  </q-badge>
                </span>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
  </q-drawer>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Services
import { UserService } from '../../services'
import useMode from '@/composables/mode'

export default {
  name: 'Sidebar',
  components: {
    User: defineAsyncComponent(() => import('../user/User.vue'))
  },
  data () {
    return {
      isOpenExpansion: false,
      isOpen: false,
      selected: '/',
      menu: this.$utils.router.getMenu(),
      userdata: JSON.parse(localStorage.getItem('userData')),
      locale: window.appOptions.locale,
      locales: window.appOptions.locales,
      theme: window.appOptions.theme,
      themes: window.appOptions.themes
    }
  },
  computed: {
    ...mapGetters([
      'isOpenSidebar',
      'appOptions',
      'currentUser',
      'isOpenSupport'
    ]),
    isEnabledChat () {
      if (this.$eventBus.getEventValue('rules').user.roles.find(({ id }) => id === 3)) {
        return true
      }

      if (!this.appOptions.support) {
        return false
      }

      return !this.$eventBus.getEventValue('rules').user.roles.find(({ id }) => (this.appOptions.appSettings.chat || { withoutAccess: [] }).withoutAccess.includes(id))
    },
    icon () {
      return this.isOpenSupport
          ? 'sentiment_very_satisfied'
          : 'sentiment_satisfied_alt'
    },
    isMobile () {
      return window.innerWidth < 700
    },
    padding () {
      return !this.isMobile
          ? 'q-pt-md'
          : ''
    },
    isEnabledSidebar () {
      if (!this.$route.matched[0] || !this.$route.matched[0].props || !this.$route.matched[0].props.default) {
        return true
      }

      return !this.$route.matched[0].props.default.disableSidebar
    }
  },
  watch: {
    $route (to) {
      if (to.path.includes('/entity')) {
        const path = to.path.slice(0, to.path.indexOf('/entity'))
        this.loadRoute(path)
        return
      }

      this.loadRoute(to.path)
    },
    // Handle changes in current user
    // Detect changes in permissions
    currentUser () {
      this.menu = this.$utils.router.getMenu()
    },
    isEnabledSidebar (value) {
      if (value) {
        this.menu = this.$utils.router.getMenu()
      }
    },
    isOpenSidebar (value) {
      if (value === this.isOpen) {
        return
      }

      this.isOpen = value
    },
    isOpen (value) {
      if (this.isOpenSidebar === value) {
        return
      }

      this.openCloseSidebar(value)
    }
  },
  setup () {
    const { classes } = useMode()

    return {
      classes,
    }
  },
  methods: {
    ...mapMutations([
      'openCloseSidebar',
      'updateAppOptions',
      'hideShowSupport',
      'toggleIsOpenQuickstart'
    ]),
    toggleQuickstart () {
      if (this.$q.screen.width < 700) {
        this.openCloseSidebar()
      }

      setTimeout(() => {
        this.toggleIsOpenQuickstart()
      })
    },
    toggleHelp () {
      const fc = document.getElementById('fc_frame')

      if (!fc) {
        return
      }

      this.hideShowSupport()

      if (this.isOpenSupport) {
        fc.style.display = 'block'
        window.fcWidget.open()
      } else {
        fc.style.display = 'none'
      }
    },
    loadRoute (path) {
      for (let i = 0; i < this.menu.length; i++) {
        const route = this.menu[i]

        if (route.path === path) {
          this.selected = path

          if (route.children) {
            this.$refs.tree.setExpanded(path, true)
          }

          return
        }

        if (route.children) {
          const el = route.children.find(x => x.path === path)

          if (el) {
            this.selected = path
            this.$refs.tree.setExpanded(route.path, true)
            return
          }
        }
      }
    },
    logout () {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      UserService.logout()
      this.$eventBus.update('rules', { user: null })
    },
    handleDarkMode () {
      this.$q.dark.set(!this.$q.dark.isActive)
      localStorage.setItem('darkMode', JSON.stringify(this.$q.dark.isActive))
    },
    handleDebug (debug) {
      this.updateAppOptions({ debug })
    },
    handleLocale (locale) {
      this.locale = locale
      window.appOptions.locale = locale

      if (this.$q.cookies) {
        this.$q.cookies.set('locale', JSON.stringify(locale))
      }

      localStorage.setItem('appOptions', JSON.stringify(window.appOptions))

      this.$i18n.locale = window.appOptions.locale.locale
    },
    handleTheme ({ theme }) {
      this.updateAppOptions({ theme })
      this.theme = theme
    },
    handleOpenProfile () {
      this.$refs.userModal.openCloseProfile()
    },
    handleClick (item, e) {
      if (!item.path || item.children) {
        this.$refs.tree.setExpanded(item.path, !this.$refs.tree.isExpanded(item.path))

        return
      }

      if (e?.ctrlKey || e?.metaKey) {
        // Open the link in a new window
        window.open(`#` + item.path, '_blank')

        return
      }

      if (item.path.includes('terminal')) {
        this.$router.push('/terminal')
            .then(() => {
              this.$eventBus.update('rules', { app: 'terminal' })
            })
        return
      }

      this.$router.push(item.path)
    }
  }
}
</script>
